import Media from "./Media";
import MediaRepository from "./MediaRepository";

export default class MediaComponent {

  private repository: MediaRepository;

  constructor(repository: MediaRepository) {
    this.repository = repository;
  }

  async createMedia(data: Media): Promise<MediaComponentCreateMediaResponse> {
    return this.repository.saveFile(data);
  }

  async getMediaURL(mediaName: string, isPreview: boolean): Promise<string> {
    return this.repository.getFileURL(mediaName, isPreview);
  }
}

interface MediaComponentCreateMediaResponse {
  filename: string
  url: string
}
