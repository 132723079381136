import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Project, ProjectState, Widget } from "@delivery-portal/utils";
import ProjectComponent, { ContentEditState, CreateProjectTO } from "../domains/project/ProjectComponent";
import { LANGUAGES } from "@delivery-portal/utils";
import { ChangeStateViewProps } from "../views/changeStateViews/ChangeStateView";
import {checkIfStateChangeIsValid} from "@delivery-portal/utils";

const ERROR = {
  PROJECT_ERROR: "no-project",
};
export default class ProjectController {
  private component: ProjectComponent;
  private cachedProjects?: Project[];
  private currentUpdatePromise?: Promise<Project[]>;
  private options: ProjectControllerOptions;
  private cacheIsInvalidated: boolean = false;

  constructor(options: ProjectControllerOptions) {
    this.component = options.component;
    this.options = options;

    this.withProject = this.withProject.bind(this);
  }

  async getProjectById(projectId: string): Promise<Project> {
    let cachedProject;
    if (this.cachedProjects) {
      cachedProject = this.cachedProjects.find(
        (project) => project.id === projectId
      );
      if (cachedProject) {
        return cachedProject;
      }
    }
    await this.updateCachedProjects();
    if (this.cachedProjects) {
      cachedProject = this.cachedProjects.find(
        (project) => project.id === projectId
      );
      if (cachedProject) {
        return cachedProject;
      }
    }
    throw new Error(ERROR.PROJECT_ERROR);
  }

  invalidateProjectCache() {
    this.cachedProjects = undefined;
  }

  withProject(Component: any) {
    return (props: any) => {
      const { pathname } = useLocation();

      const [projectList, setProjectList] = useState<Project[] | undefined>(
        this.cachedProjects
      );
      const [selectedProject, setSelectedProject] = useState<Project>();
      const [masterProject, setMasterProject] = useState<Project>();

      const [projectListError, setProjectListError] = useState<string>();
      const [exportProjectError, setExportProjectError] = useState<string>();
      const [createProjectError, setCreateProjectError] = useState<string>();
      const [updateProjectError, setUpdateProjectError] = useState<string>();
      const [
        selectedProjectError,
        setSelectedProjectError,
      ] = useState<string>();

      useEffect(() => {
        if (!this.cachedProjects || this.cachedProjects.length === 0) {
          this.updateCachedProjects()
            .then(() => setProjectList(this.cachedProjects))
            .catch((error) => setProjectListError(error.message));
        }
        const projectId = pathname.substr(pathname.lastIndexOf("/") + 1);
        if (
          projectId &&
          projectId.length === 36 &&
          projectId.indexOf("-") === 8
        ) {
          this.getProjectById(projectId)
            .then((project) => {
              setSelectedProject(project);
              if (project.masterRefId) {
                this.getProjectById(project.masterRefId).then((masterProject) => setMasterProject(masterProject));
              }
            })
            .catch((error) => setSelectedProjectError(error.message));
        }
      }, [pathname]);

      const projectProps: ProjectProps = {
        project: {
          projectList,
          selectedProject,
          masterProject,
          languages: LANGUAGES,
          reloadList: () => {
            this.updateCachedProjects()
              .then(() => setProjectList(this.cachedProjects))
              .catch((error) => setProjectListError(error.message));
          },
          create: async (data: CreateProjectTO) => {
            setCreateProjectError(undefined);
            setSelectedProject(undefined);
            try {
              return await this.component.createNewProject(data);
            } catch (error) {
              if (error instanceof Error) {
                setCreateProjectError(error.message);
              }
              return;
            }
          },
          update: async () => {
            setUpdateProjectError(undefined);
            try {
              if (selectedProject) {
                await this.component.saveProject(selectedProject);
                return true;
              } else {
                setUpdateProjectError("ProjectController::No project is selected");
              }
            } catch (error) {
              if (error instanceof Error) {
                setUpdateProjectError(error.message);
              }
            }
            return false;
          },
          getPreviewURL: async (projectId) => {
            return `${this.options.webserviceRoot}preview/${projectId}`;
          },
          getExportURL: async (projectId) => {
            try {
              return await this.component.getExportURLForProjectId(projectId);
            } catch (error) {
              if (error instanceof Error) {
                setExportProjectError(error.message);
              }
              return "";
            }
          },
          getEmbedCode: async (project, widget, containerSelector) => {
            return this.component.generateEmbedCode(
              project,
              widget,
              containerSelector
            );
          },
          sendToTranslation: async (projectId, deadline) => {
            const project = await this.getProjectById(projectId);
            return this.component.sendProjectForTranslation(project, deadline);
          },
          isValidNewState: (project, newState) => {
            return checkIfStateChangeIsValid(project, newState);
          },
          isEditable: (project) => {
            return this.component.isEditable(project);
          },
          isMetaDataEditable: (project) => {
            return this.component.isMetaDataEditable(project);
          },
          getContentEditState: (project) => {
            return this.component.getContendEditState(project);
          },
          getValidStates: (project) => {
            return this.component.getValidStates(project);
          },
          deleteProject: (project) => {
            return this.component.deleteProject(project);
          }
        },
        error: {
          ...props.error,
          exportProjectError,
          createProjectError,
          projectListError,
          selectedProjectError,
          updateProjectError,
        },
      };
      return <Component key="with-project" {...props} {...projectProps} />;
    };
  }

  withStateChange(Component: any) {
    return (props: ProjectProps & ChangeStateViewProps) => {
      const targetState = ProjectState[props.newState as keyof typeof ProjectState];
      if (targetState === ProjectState.DEV) {
        if (props.project.selectedProject) {
          props.project.selectedProject.masterRefId = undefined;
        }
      }
      return <Component key="with-state-change" {...props} />
    }
  }

  private async updateCachedProjects(): Promise<Project[]> {
    if (this.currentUpdatePromise) return this.currentUpdatePromise;
    this.currentUpdatePromise = this.component.getAllProjects();
    this.cachedProjects = await this.currentUpdatePromise;
    this.currentUpdatePromise = undefined;
    return this.cachedProjects;
  }
}

interface ProjectControllerOptions {
  component: ProjectComponent
  webserviceRoot: string
}

export interface ProjectStateChangeProps {
  project: {
    selectedProject?: Project
  }
}

export interface ProjectProps {
  project: {
    selectedProject?: Project
    masterProject?: Project
    projectList?: Project[]
    languages: { [key: string]: string }
    getExportURL: (projectId: string) => Promise<string>
    getEmbedCode: (
      project: Project,
      widget: Widget,
      containerSelector: string
    ) => Promise<string>
    getPreviewURL: (projectId: string) => Promise<string>
    create: (data: CreateProjectTO) => Promise<string | undefined>
    update: () => Promise<boolean>
    reloadList: () => void
    sendToTranslation: (projectId: string, deadline: Date) => Promise<string | Error>
    isValidNewState: (project: Project, newState: ProjectState) => boolean
    isEditable: (project: Project) => boolean
    isMetaDataEditable: (project: Project) => boolean
    getContentEditState: (project: Project) => ContentEditState
    getValidStates: (project: Project) => ProjectState[]
    deleteProject: (project: Project) => Promise<boolean>
  };
  error: {
    exportProjectError?: string;
    createProjectError?: string;
    updateProjectError?: string;
    selectedProjectError?: string;
    projectListError?: string;
  };
}

