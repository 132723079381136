import axios from "axios";
import { UserRepository } from "../domains/user/UserRepository";


export default class RESTRepository {

  protected options: RESTRepositoryOptions;
  protected useUserAuth: boolean = true;

  constructor(options: RESTRepositoryOptions) {
    this.options = options;
  }

  protected async get<T extends RESTResponse = any>(url: string, optionalHeaders: { [key: string]: any } = {}): Promise<T> {
    const headers = await this.buildHeaders(optionalHeaders);
    const response = await axios.get<T>(`${this.options.endpoint}${url}${this.options.key}`, {
      headers,
    });
    if (response.data.error) throw new Error(response.data.error)
    return response.data;
  }

  protected async post<T extends RESTResponse = any>(url: string, data: any, optionalHeaders: { [key: string]: any } = {}): Promise<T> {
    const headers = await this.buildHeaders(optionalHeaders);
    const response = await axios.post<T>(`${this.options.endpoint}${url}${this.options.key}`, data, {
      headers,
    });
    if (response.data.error) throw new Error(response.data.error)
    return response.data;
  }

  protected async patch<T extends RESTResponse = any>(url: string, data: any, optionalHeaders: { [key: string]: any } = {}): Promise<T> {
    const headers = await this.buildHeaders(optionalHeaders);
    const response = await axios.patch<T>(`${this.options.endpoint}${url}${this.options.key}`, data, {
      headers,
    });
    if (response.data.error) throw new Error(response.data.error)
    return response.data;
  }

  protected async del<T extends RESTResponse = any>(url: string, optionalHeaders: { [key: string]: any } = {}): Promise<T> {
    const headers = await this.buildHeaders(optionalHeaders);
    const response = await axios.delete<T>(`${this.options.endpoint}${url}${this.options.key}`, {
      headers,
    });
    if (response.data.error) throw new Error(response.data.error)
    return response.data;
  }


  private async buildHeaders(optionalHeaders: { [key: string]: any }): Promise<{ [key: string]: any }> {
    const headers = {};
    if (this.useUserAuth && this.options.userRepository) {
      const accessToken = await this.options.userRepository.getToken();
      // @ts-ignore
      headers.Authorization = `Bearer ${accessToken}`;
    } else if (this.options.authorization) {
      // @ts-ignore
      headers.Authorization = this.options.authorization;
    }
    return { ...headers, ...optionalHeaders };
  }
}

export interface RESTRepositoryOptions {
  endpoint: string
  key: string
  userRepository?: UserRepository
  authorization?: string
}

export interface RESTResponse {
  error?: string
}
