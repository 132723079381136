import { Conversation, Comment } from "../domains/comments/Comment";
import CommentComponent from "../domains/comments/CommentComponent";
import { Project } from "@delivery-portal/utils";

export default class CommentController {
  private options: CommentControllerOptions;

  private requests: { [key: string]: Promise<any> };

  constructor(options: CommentControllerOptions) {
    this.options = options;
    this.requests = {};

    this.withComment = this.withComment.bind(this);
  }

  withComment(Component: any) {
    return (props: any) => {
      const actionProps: CommentProps = {
        comment: {
          getProjectConversation: (project: Project) => {
            if (!this.requests[project.id]) {
              this.requests[project.id] = this.options.component.getProjectConversation(project);
              this.requests[project.id].finally(() => {
                delete this.requests[project.id];
              })
            }
            return this.requests[project.id];
          },
          addComment: (conversation: Conversation, text: string) => {
            return this.options.component.addComment(conversation, text);
          },
          removeComment: (conversation: Conversation, comment: Comment) => {
            return this.options.component.removeComment(conversation, comment);
          },
          editComment: (conversation: Conversation, comment: Comment, text: string) => {
            return this.options.component.editComment(conversation, comment, text);
          },
        },
      };
      return <Component {...props} {...actionProps} />;
    };
  }
}

interface CommentControllerOptions {
  component: CommentComponent;
}

export interface CommentProps {
  comment: {
    getProjectConversation: (project: Project) => Promise<Conversation>;
    addComment: (
      conversation: Conversation,
      text: string
    ) => Promise<Conversation>;
    removeComment: (
      conversation: Conversation,
      comment: Comment
    ) => Promise<Conversation>;
    editComment: (
      conversation: Conversation,
      comment: Comment,
      text: string
    ) => Promise<Conversation>;
  };
}
