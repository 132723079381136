import ConfigurationComponent from "../domains/configuration/ConfigurationComponent";
import { ConfigurationResponse } from "../domains/configuration/ConfigurationRepository";

let configuration: ConfigurationResponse = {};

export default class ConfigurationController {
  options: ConfigurationControllerOptions;

  constructor(options: ConfigurationControllerOptions) {
    this.options = options;
  }

  async init() {
    if (this.options.component) {
      configuration = {
        ...configuration,
        ...await this.options.component.loadConfiguration(),
      };
    } else {
      throw new Error('Cannot init configuration without ConfigurationComponent');
    }
  }

  setValueForKey(key: ConfigurationKey, value: string) {
    configuration[key] = value;
  }

  getValueByKey(key: ConfigurationKey, defaultValue?: string): string {
    const value = configuration[key];
    if (defaultValue !== undefined) return value || defaultValue;
    if (value !== undefined) {
      return value;
    } else {
      throw new Error(`Missing required configuration key (${key})`);
    }
  }

  withConfiguration(Component: any) {
    return (props: any) => {
      const actionProps: ConfigurationProps = { configuration };

      return <Component {...props} {...actionProps} />
    }
  }
}

interface ConfigurationControllerOptions {
  component?: ConfigurationComponent
}

export interface ConfigurationProps {
  configuration: ConfigurationResponse
};

export enum ConfigurationKey {
  DEVOPS_PAT = 'DEVOPS_PAT',
  MEDIA_BLOB_STORAGE_PREVIEW_SAS = 'MEDIA_BLOB_STORAGE_PREVIEW_SAS',
  MSAL_CLIENT_ID = 'MSAL_CLIENT_ID',
  MSAL_TENANT_ID = 'MSAL_TENANT_ID',
  MSAL_SCOPES = 'MSAL_SCOPES',
  WS_ENDPOINT = 'WS_ENDPOINT',
  WS_ENDPOINT_KEY = 'WS_ENDPOINT_KEY',
  API_VERSION = 'API_VERSION',
}
