
import RESTRepository, { RESTResponse } from "../../utils/RESTRepository";
import { Project } from "@delivery-portal/utils";
import ProjectRepository from "./ProjectRepository";

const API = {
  CREATE: 'create',
  EXPORT: 'export',
  TRANSLATE: 'translate',
  UPDATE: 'update',
  PREVIEW: 'preview',
  LIST: 'list',
  DELETE: 'delete',
};

const ERROR = {
  GENERIC: 'RESTProjectRepository::An error occured'
}

export default class RESTProjectRepository extends RESTRepository implements ProjectRepository {

  async listProjects(): Promise<Project[]> {
    const response = await this.get<ListResponse>(API.LIST);
    if (response.projects) return response.projects.map((project) => {
      return {
        ...project,
        ...{
          creationDate: project.creationDate ? new Date(project.creationDate) : undefined,
          lastModified: project.lastModified ? new Date(project.lastModified) : undefined,
        }
      }
    })
    throw new Error(ERROR.GENERIC);
  }
  async createProject(project: Project): Promise<string> {
    const response = await this.post<ProjectUpdateResponse>(API.CREATE, { project });
    if (response.projectId) return response.projectId
    throw new Error(ERROR.GENERIC);
  }
  async updateProject(project: Project): Promise<string> {
    const response = await this.post<ProjectUpdateResponse>(`${API.UPDATE}/${project.id}`, { project });
    if (response.projectId) return response.projectId
    throw new Error(ERROR.GENERIC);
  }

  async exportProject(projectId: string): Promise<string> {
    const response = await this.get<ExportResponse>(`${API.EXPORT}/${projectId}`);
    if (response.url) return response.url;
    throw new Error(ERROR.GENERIC);
  }

  async previewProject(projectId: string): Promise<string> {
    return `${this.options.endpoint}${API.PREVIEW}/${projectId}${this.options.key}`;
  }

  async translateProject(projectId: string, deadline: Date): Promise<string> {
    const response = await this.get<TranslateResponse>(`${API.TRANSLATE}/${projectId}/${deadline.toISOString()}`);
    if (response.translationJobId) return response.translationJobId;
    throw new Error(ERROR.GENERIC);
  }

  async deleteProject(projectId: string): Promise<boolean> {
    const response = await this.get<DeleteResponse>(`${API.DELETE}/${projectId}`);
    return response.deleteStatus;
  }
}

interface TranslateResponse extends RESTResponse {
  translationJobId?: string
}

interface ExportResponse extends RESTResponse {
  url?: string
}

interface ListResponse extends RESTResponse {
  projects?: Project[]
}

interface ProjectUpdateResponse extends RESTResponse {
  projectId?: string
}

interface DeleteResponse extends RESTResponse {
  errno?: number
  deleteStatus: boolean
}
