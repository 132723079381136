import RESTRepository, { RESTResponse } from "../../utils/RESTRepository";
import ConfigurationRepository, { ConfigurationResponse } from "./ConfigurationRepository";

export default class RESTConfigurationRepository extends RESTRepository implements ConfigurationRepository {

  async init(): Promise<ConfigurationResponse> {
    return this.get<ConfigurationResponse & RESTResponse>('configuration');
  }


}

