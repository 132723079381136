import CommentRepository from './CommentRepository';
import RESTRepository, { RESTRepositoryOptions, RESTResponse } from "../../utils/RESTRepository";
import { Conversation, Comment } from './Comment';


const API = {
  SEARCH: 'https://almsearch.dev.azure.com/dgsit/Solutions%20Development/_apis/search/workitemsearchresults?api-version=6.0-preview.1',
  GET: 'https://dev.azure.com/dgsit/Solutions%20Development/_apis/wit/workitems/{id}?api-version=6.0',
  ADD: 'https://dev.azure.com/dgsit/Solutions%20Development/_apis/wit/workItems/{workItemId}/comments?api-version=6.0-preview.3',
  UPDATE: 'https://dev.azure.com/dgsit/Solutions%20Development/_apis/wit/workItems/{workItemId}/comments/{commentId}?api-version=6.0-preview.3',
  DELETE: 'https://dev.azure.com/dgsit/Solutions%20Development/_apis/wit/workItems/{workItemId}/comments/{commentId}?api-version=6.0-preview.3',
  CREATE: 'https://dev.azure.com/dgsit/Solutions%20Development/_apis/wit/workitems/$feature?api-version=6.0',
}

const ERROR = {
  NO_CONVERSATION: 'No conversation found for project {id}'
}

export default class RESTCommentRepository extends RESTRepository implements CommentRepository {

  constructor(options: RESTCommentRepositoryOptions) {
    super(options);
    this.options.authorization = `Basic ${btoa(`:${options.accessToken}`)}`;
    this.useUserAuth = false;
  }

  async getConversationForProjectId(projectId: string): Promise<Conversation> {
    const response = await this.post<SearchResponse>(API.SEARCH, {
      $top: 1,
      filters: null,
      searchText: `${projectId}`,
      includeFacets: false,
    });
    if (response.count > 0) {
      const projectConversation = response.results[0];
      const conversationId = projectConversation.fields['system.id'];

      const conversation = new Conversation();
      conversation.id = conversationId;

      const workItemURL = API.GET.replace('{id}', `${conversationId}`);
      const workItemResponse = await this.get<GetResponse>(workItemURL);
      if (workItemResponse._links.workItemComments) {
        const commentsURL = workItemResponse._links.workItemComments.href;
        const allComments = await this.get<ListResponse>(commentsURL);
        conversation.comments = allComments.comments;
      }

      return conversation;
    }

    throw new Error(ERROR.NO_CONVERSATION.replace('{id}', projectId));
  }

  async createConversationForProject(projectId: string, title: string): Promise<Conversation> {
    const response = await this.post<CreateResponse>(
      API.CREATE,
      [
        {
          op: 'add',
          path: '/fields/System.Title',
          from: null,
          value: title,
        },
        {
          op: 'add',
          path: '/relations/-',
          from: null,
          value: {
              'rel': 'System.LinkTypes.Hierarchy-Reverse',
              'url': 'https://dev.azure.com/dgsit/4e415c58-b24d-4ad0-a5b5-df8ba0c0c728/_apis/wit/workItems/160215',
              'attributes': {
                  'isLocked': false,
                  'name': 'Parent'
              }
          }
      }
      ], {
        'Content-Type': 'application/json-patch+json'
      },
    );

    const conversation = new Conversation();
    conversation.id = response.id;

    return conversation;
  }

  async addComment(conversationId: string, comment: string): Promise<Comment> {
    const response = await this.post<AddResponse>(API.ADD.replace('{workItemId}', conversationId), {
      text: comment
    });
    return response;
  }

  async updateComment(conversationId: string, commentId: string, comment: string): Promise<Comment> {
    const response = await this.patch<AddResponse>(
      API.UPDATE.replace('{workItemId}', conversationId).replace('{commentId}', commentId),
      {
        text: comment
      },
      {
        'Content-Type': 'application/json-patch+json'
      },
    );
    return response;
  }

  async deleteComment(conversationId: string, commentId: string): Promise<void> {
    await this.del<AddResponse>(API.DELETE.replace('{workItemId}', conversationId).replace('{commentId}', commentId));
    return;
  }
}

interface RESTCommentRepositoryOptions extends RESTRepositoryOptions {
  accessToken: string
}

interface AddResponse extends RESTResponse, Comment {}
interface ListResponse extends RESTResponse {
  totalCount: number
  count: number
  comments: Comment[]
}
interface GetResponse extends RESTResponse {
  _links: {
    workItemComments?: {
      href: string
    }
  }
}

interface SearchResponse extends RESTResponse {
  count: number
  results: {
    fields: {
      'system.id': number
    }
  }[]
}

interface CreateResponse extends RESTResponse {
  id: number
}
