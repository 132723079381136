import React, { useEffect, useState } from 'react';
import { Widget } from '@delivery-portal/utils';
import WidgetComponent from '../domains/widget/WidgetComponent';


const ERROR = {
  WIDGET_ERROR: 'no-widget'
}

export default class WidgetController {
  private component: WidgetComponent;
  private cachedWidgets: Widget[] = [];

  constructor(options: WidgetControllerOptions) {
    this.component = options.component;

    this.withWidget = this.withWidget.bind(this);
  }

  withWidget(Component: any) {
    return (props: any) => {
      const [widgetList, setWidgetList] = useState(this.cachedWidgets);
      const [selectedWidget, setSelectedWidget] = useState<Widget>();
      const [widgetListError, setWidgetListError] = useState<string>();
      const [selectedWidgetError, setSelectedWidgetError] = useState<string>();

      useEffect(() => {
        if (this.cachedWidgets.length === 0) {
          this.updateCachedWidgets().then(() => setWidgetList(this.cachedWidgets)).catch((error) => setWidgetListError(error.message));
        }
      }, []);

      const actionProps: WidgetProps = {
        widget: {
          widgetList,
          widgetIds: (() => {
            if (widgetList) {
              const ids = widgetList.map((widget) => widget.id);
              return ids.filter((id, index, self) => self.indexOf(id) === index)
            }
            return []
          })(),
          selectedWidget,
          getWidgetVersions: (widgetId) => {
            if (widgetList) {
              const widgets = widgetList.filter((widget) => widget.id === widgetId);
              if (process.env.REACT_APP_ENV !== 'preview') {
                const filterPreview = widgets.filter((widget) => {
                  return widget.version !== "preview";
                })
                return filterPreview.map((widget) => widget.version).reverse();
              }
              return widgets.map((widget) => widget.version).reverse();
            }
            return [];
          },
          getWidgetThemes: (widgetId, widgetVersion) => {
            if (widgetList) {
              const widget = widgetList.find((widget) => widget.id === widgetId && widget.version === widgetVersion);
              if (widget) {
                const { files } = widget.manifest;
                if (files) {
                  const { themes } = files;
                  if (themes) {
                    return Object.keys(themes);
                  }
                }
              }
              return [];
            }
            return [];
          },
          reloadList: () => {
            this.updateCachedWidgets().then(() => setWidgetList(this.cachedWidgets)).catch((error) => setWidgetListError(error.message));
          },
          getWidget: (widgetId, version) => {
            this.getWidgetById(widgetId, version).then((widget) => setSelectedWidget(widget)).catch((error) => setSelectedWidgetError(error.message));
          }
        },
        error: {
          ...props.error,
          widgetListError,
          selectedWidgetError
        }
      }
      return <Component key="with-widget" {...props} {...actionProps} />
    }
  }

  private async updateCachedWidgets(): Promise<Widget[]> {
    this.cachedWidgets = await this.component.getAllWidgets()
    return this.cachedWidgets;
  }

  private async getWidgetById(widgetId: string, version: string): Promise<Widget> {
    let cachedWidget = this.cachedWidgets.find((widget) => widget.id === widgetId && widget.version === version);
    if (cachedWidget) {
      return cachedWidget;
    }
    await this.updateCachedWidgets;
    cachedWidget = this.cachedWidgets.find((widget) => widget.id === widgetId);
    if (cachedWidget) {
      return cachedWidget;
    }
    throw new Error(ERROR.WIDGET_ERROR);
  }
}

interface WidgetControllerOptions {
  component: WidgetComponent
}

export interface WidgetProps {
  widget: {
    widgetList?: Widget[]
    widgetIds?: string[]
    selectedWidget?: Widget
    getWidgetVersions: (widgetId: string) => string[]
    getWidgetThemes: (widgetId: string, widgetVersion: string) => string[]
    reloadList: () => void
    getWidget: (widgetId: string, version: string) => void
  },
  error: {
    widgetListError?: string
    selectedWidgetError?: string
  }
}
