import {User} from "@delivery-portal/utils";
import { UserRepository } from "./UserRepository";

export default class UserComponent {
  private options: UserComponentOptions;

  constructor(options: UserComponentOptions) {
    this.options = options;
  }

  async getCurrentUser(): Promise<User> {
    return await this.options.repository.currentUser();
  }

  login(): Error | undefined {
    return this.options.repository.login();
  }

  async checkLogin() {
    await this.options.repository.handleLogin();
  }

  async getAccessToken() {
    return await this.options.repository.getToken();
  }
}

interface UserComponentOptions {
  repository: UserRepository
}
