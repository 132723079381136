import RESTRepository, { RESTRepositoryOptions, RESTResponse } from "../../utils/RESTRepository";
import Media from "./Media";
import MediaRepository from "./MediaRepository";

const API = {
  UPLOAD: 'uploadMedia',
};

const ERROR = {
  GENERIC: 'RESTMediaRepository::An error occured',
  MISSING_DATA: 'RESTMediaRepository::Missing either fileName or fileData'
}
export default class RESTMediaRepository extends RESTRepository implements MediaRepository {

  private previewSAS: string;

  constructor(options: RESTMediaRepositoryOptions) {
    super(options);
    this.previewSAS = options.previewSAS;
  }
  async getFileURL(fileName: string, isPreview: boolean): Promise<string> {
    return `https://${isPreview ? 'preview-media' : 'media'}.delivery-portal.demant.com/${fileName}${this.previewSAS}`;
  }

  async saveFile(file: Media): Promise<{filename: string, url: string}> {
    if (file.fileName && file.fileData) {
      const formData = new FormData();
      formData.append('media', file.fileData);
      formData.append('filename', file.fileName);
      if (file.altTitle) {
        formData.append('alt', file.altTitle);
      }

      const response = await this.post<UploadMediaResponse>(API.UPLOAD, formData);
      if (response.location){
        return {
          filename: response.filename || file.fileName,
          url: response.location,
        };
      }
      throw new Error(ERROR.GENERIC);
    }
    throw new Error(ERROR.GENERIC);
  }
}

interface RESTMediaRepositoryOptions extends RESTRepositoryOptions {
  previewSAS: string
}

interface UploadMediaResponse extends RESTResponse {
  location?: string
  filename?: string
}
