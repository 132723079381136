import MediaController from './controllers/MediaController';
import ProjectController from './controllers/ProjectController';
import UIController from './controllers/UIController';
import UserController from './controllers/UserController';
import WidgetController from './controllers/WidgetController';
import MediaComponent from './domains/media/MediaComponent';
import RESTMediaRepository from './domains/media/RESTMediaRepository';
import ProjectComponent from './domains/project/ProjectComponent';
import RESTProjectRepository from './domains/project/RESTProjectRepository';
import MSALUserRepository from './domains/user/MSALUserRepository';
import UserComponent from './domains/user/UserComponent';
import RESTWidgetRepository from './domains/widget/RESTWidgetRepository';
import WidgetComponent from './domains/widget/WidgetComponent';
import RESTCommentRepository from './domains/comments/RESTCommentRepository'
import CommentController from './controllers/CommentController';
import CommentComponent from './domains/comments/CommentComponent';
import ConfigurationController, { ConfigurationKey } from './controllers/ConfigurationController';
import ConfigurationComponent from './domains/configuration/ConfigurationComponent';
import RESTConfigurationRepository from './domains/configuration/RESTConfigurationRepository';

//##################### HARD CODED CONFIGURATION ###########################
let WS_ENDPOINT = 'https://delivery-portal-webservices-prod.azurewebsites.net/api/';
if (process.env.REACT_APP_ENV === 'local') {
  WS_ENDPOINT = 'http://localhost:7071/api/';
}
if (process.env.REACT_APP_ENV === 'preview') {
  WS_ENDPOINT = 'https://delivery-portal-webservices-preview.azurewebsites.net/api/';
}
const MSAL_CLIENT_ID = 'e0b2bebc-8200-4534-8d40-d35342d1b7d4';
const MSAL_TENANT_ID = '9bf8c7a8-e008-49a7-9e43-ab76976c4bf8';
const MSAL_SCOPES = 'user.read;api://e0b2bebc-8200-4534-8d40-d35342d1b7d4/user_impersonation';
//##################### HARD CODED CONFIGURATION ###########################







//##################### USER CONTROLLER ###########################
const userRepository = new MSALUserRepository({
  clientId: MSAL_CLIENT_ID,
  tenantId: MSAL_TENANT_ID,
  scopes: MSAL_SCOPES.split(';')
});
const userController = new UserController({
  component: new UserComponent({
    repository: userRepository
  })
});
//##################### USER CONTROLLER ###########################






//##################### CONFIGURATION CONTROLLER ###########################
const configurationController = new ConfigurationController({
  component: new ConfigurationComponent({
    repository: new RESTConfigurationRepository({
      endpoint: WS_ENDPOINT,
      key: '',
      userRepository,
    })
  })
});
configurationController.setValueForKey(ConfigurationKey.WS_ENDPOINT, WS_ENDPOINT);
configurationController.setValueForKey(ConfigurationKey.MSAL_CLIENT_ID, MSAL_CLIENT_ID);
configurationController.setValueForKey(ConfigurationKey.MSAL_TENANT_ID, MSAL_TENANT_ID);
configurationController.setValueForKey(ConfigurationKey.MSAL_SCOPES, MSAL_SCOPES);

const initConfiguration = configurationController.init.bind(configurationController);
//##################### CONFIGURATION CONTROLLER ###########################










const getLoginActions = () => {
  const {
    isLoggedIn,
    login
  } = userController;

  return {
    isLoggedIn,
    login,
  }
};






const getActions = () => {

  //##################### CONFIGURATION VARS ###########################
  const WS_ENDPOINT = configurationController.getValueByKey(ConfigurationKey.WS_ENDPOINT);
  const WS_ENDPOINT_KEY = configurationController.getValueByKey(ConfigurationKey.WS_ENDPOINT_KEY, '');
  const DEVOPS_PAT = configurationController.getValueByKey(ConfigurationKey.DEVOPS_PAT);
  const MEDIA_PREVIEW_SAS = configurationController.getValueByKey(ConfigurationKey.MEDIA_BLOB_STORAGE_PREVIEW_SAS);
  //##################### CONFIGURATION VARS ###########################






  //##################### MEDIA CONTROLLER ###########################
  const mediaController = new MediaController(
    new MediaComponent(
      new RESTMediaRepository({
        endpoint: WS_ENDPOINT,
        key: WS_ENDPOINT_KEY,
        previewSAS: MEDIA_PREVIEW_SAS,
        userRepository,
      })
    )
  );
  //##################### MEDIA CONTROLLER ###########################







  //##################### WIDGET CONTROLLER ###########################
  const widgetComponent = new WidgetComponent({
    repository: new RESTWidgetRepository({
      endpoint: WS_ENDPOINT,
      key: WS_ENDPOINT_KEY,
      userRepository,
    })
  });
  const widgetController = new WidgetController({
    component: widgetComponent
  });
  //##################### WIDGET CONTROLLER ###########################






  //##################### COMMENT CONTROLLER ###########################
  // PAT azure devops - expires 2021-12-31
  // 426dao2vg2tvqehmprlhf64mzln7tl4jxursiyvarhnmgbtusj6q
  const commentComponent = new CommentComponent({
    repository: new RESTCommentRepository({
      endpoint: '',
      key: '',
      accessToken: DEVOPS_PAT,
    }),
  });
  const commentController = new CommentController({
    component: commentComponent,
  });
  //##################### COMMENT CONTROLLER ###########################






  //##################### PROJECT CONTROLLER ###########################
  const projectController = new ProjectController({
    component: new ProjectComponent({
      embedCodeApiBasePath: WS_ENDPOINT.replace('/api/', ''),
      repository: new RESTProjectRepository({
        endpoint: WS_ENDPOINT,
        key: WS_ENDPOINT_KEY,
        userRepository,
      }),
      widgetComponent,
      commentComponent,
    }),
    webserviceRoot: WS_ENDPOINT,
  });
  //##################### PROJECT CONTROLLER ###########################











  //##################### UI CONTROLLER ###########################
  const modalRoot = document.querySelector('#modal-root');

  const uiController = new UIController({
    modalContainer: modalRoot?.querySelector('.content') || undefined,
    onModalShow: () => {
      const body = document.querySelector('body');
      if (body) {
        body.classList.add('inactive');
      }
      if (modalRoot) {
        modalRoot.classList.add('active');
      }
    },
    onModalHide: () => {
      const body = document.querySelector('body');
      if (body) {
        body.classList.remove('inactive');
      }
      if (modalRoot) {
        modalRoot.classList.remove('active');
      }

      projectController.invalidateProjectCache();
    }
  });
  //##################### UI CONTROLLER ###########################






  const {
    withProject,
    withStateChange,
  } = projectController;

  const {
    withWidget
  } = widgetController;

  const {
    withMedia
  } = mediaController;

  const {
    withUser
  } = userController;

  const {
    withModal
  } = uiController;

  const {
    withComment
  } = commentController;

  const {
    withConfiguration
  } = configurationController;

  return {
    withMedia,
    withProject,
    withStateChange,
    withWidget,
    withModal,
    withUser,
    withComment,
    withConfiguration,
    userRepository,
  }
};



export {
  getActions,
  getLoginActions,
  initConfiguration,
};
