import RESTRepository, { RESTResponse } from "../../utils/RESTRepository";
import {Widget} from "@delivery-portal/utils";
import WidgetRepository from "./WidgetRepository";

const API = {
  LIST: 'listWidgets',
};

const ERROR = {
  GENERIC: 'RESTWidgetRepository::An error occured'
}
export default class RESTWidgetRepository extends RESTRepository implements WidgetRepository {

  async listWidgets(): Promise<Widget[]> {
    const response = await this.get<ListResponse>(API.LIST);
    if (response.widgets) return response.widgets;
    throw new Error(ERROR.GENERIC);
  }

}

interface ListResponse extends RESTResponse {
  widgets?: Widget[]
}

