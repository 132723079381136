import React, { useEffect, useState } from "react";
import {User} from "@delivery-portal/utils";
import UserComponent from "../domains/user/UserComponent";

export default class UserController {
  private component: UserComponent;

  constructor(options: UserControllerOptions) {
    this.component = options.component;

    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.login = this.login.bind(this);
    this.withUser = this.withUser.bind(this);
  }

  async isLoggedIn(): Promise<boolean> {
    await this.component.checkLogin();
    const user = await this.component.getCurrentUser();
    const isLoggedIn = !user.isUnknown();
    if (isLoggedIn) {
      await this.component.getAccessToken();
    }
    return isLoggedIn;
  }

  login() {
    this.component.login();
  }

  withUser(Component: any) {
    return (props: any) => {
      const [current, setCurrent] = useState<User>();

      useEffect(() => {
        this.component.getCurrentUser().then((user) => { setCurrent(user) });
      }, []);

      const actionProps: CurrentUserProps = {
        user: {
          current,
        }
      }
      return < Component {...props} {...actionProps} />
    }
  }
}

interface UserControllerOptions {
  component: UserComponent
}

export interface CurrentUserProps {
  user: {
    current?: User
  }
}
