import {Widget} from "@delivery-portal/utils";
import WidgetRepository from "./WidgetRepository";

export default class WidgetComponent {
  private options: WidgetComponentOptions;

  constructor(options: WidgetComponentOptions) {
    this.options = options;
  }

  async getAllWidgets(): Promise<Widget[]> {
    return this.options.repository.listWidgets();
  }
}

interface WidgetComponentOptions {
  repository: WidgetRepository;
}
