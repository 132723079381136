import ConfigurationRepository from "./ConfigurationRepository";

export default class ConfigurationComponent {
  private options: ConfigurationComponentOptions;

  constructor(options: ConfigurationComponentOptions) {
    this.options = options;
  }

  async loadConfiguration() {
    return this.options.repository.init();
  }
}

interface ConfigurationComponentOptions {
  repository: ConfigurationRepository
}
