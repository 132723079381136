import { User } from '@delivery-portal/utils';
import { UserRepository } from "./UserRepository";
import {
  Configuration,
  PublicClientApplication,
  AccountInfo,
  AuthenticationResult,
} from '@azure/msal-browser';

const ERROR = {
  UNAUTHORIZED: 'unauthorized'
};

export default class MSALUserRepository implements UserRepository {
  private options: MSALUserRepositoryOptions;
  private configuration: Configuration
  private msalInstance: PublicClientApplication;
  private currentToken?: AuthenticationResult;


  constructor(options: MSALUserRepositoryOptions) {
    this.options = options;

    this.configuration = {
      auth: {
        clientId: this.options.clientId,
        authority: `https://login.microsoftonline.com/${this.options.tenantId}`,
        redirectUri: this.options.redirectURI
      }
    }

    this.msalInstance = new PublicClientApplication(this.configuration);
  }

  login(): Error | undefined {
    try {
      this.msalInstance.loginRedirect({
        scopes: this.options.scopes
      });
    } catch (err) {
      return err as Error;
    }
    return
  }
  async handleLogin(): Promise<User> {
    let user = new User();
    try {
      const tokenResponse = await this.msalInstance.handleRedirectPromise();
      if (tokenResponse !== null) {
        this.currentToken = tokenResponse;

        user = await this.currentUser()
      }
    } catch (error) {
      // TODO...
    }
    return user;
  }
  async currentUser(): Promise<User> {
    const account = this.getCurrentAccount();

    if (account) {
      return new User({
        name: account.name,
        email: account.username,
      });
    }

    return new User();
  }

  async getToken(): Promise<string> {
    const account = this.getCurrentAccount();
    const tokenRequest = { scopes: ['api://e0b2bebc-8200-4534-8d40-d35342d1b7d4/user_impersonation'], account };
    try {
      const token = await this.msalInstance.acquireTokenSilent(tokenRequest);
      const { expiresOn, accessToken } = token;
      if (expiresOn) {
        if (expiresOn > new Date()) {
          return accessToken;
        }
      }
    } catch (error) {
      this.msalInstance.acquireTokenRedirect(tokenRequest)
    }
    // renew token
    throw new Error(ERROR.UNAUTHORIZED)
  }

  getCurrentAccount(): AccountInfo | undefined {
    const myAccounts: AccountInfo[] = this.msalInstance.getAllAccounts();
    if (myAccounts.length) {
      return myAccounts[0];
    }
  }
}

interface MSALUserRepositoryOptions {
  clientId: string
  tenantId: string
  redirectURI?: string
  scopes: string[]
}
