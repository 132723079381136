import {Project} from "@delivery-portal/utils";
import { Conversation, Comment } from "./Comment";
import CommentRepository from "./CommentRepository";

export default class CommentComponent {
  private options: CommentComponentOptions;

  constructor(options:CommentComponentOptions) {
    this.options = options;
  }

  async getProjectConversation(project:Project):Promise<Conversation> {
    try {
      return await this.options.repository.getConversationForProjectId(project.id);
    } catch(error) {
      const title = `${project.name} (${project.id})`;
      return await this.options.repository.createConversationForProject(project.id, title);
    }
  }

  async addComment(conversation:Conversation, text: string): Promise<Conversation> {
    const comment = await this.options.repository.addComment(`${conversation.id}`, text);

    conversation.comments.push(comment);

    return conversation;
  }

  async editComment(conversation:Conversation, comment:Comment, text: string): Promise<Conversation> {
    await this.options.repository.updateComment(`${conversation.id}`, `${comment.id}`, text);
    return this.options.repository.getConversationForProjectId(conversation.projectId);
  }

  async removeComment(conversation:Conversation, comment:Comment): Promise<Conversation> {
    await this.options.repository.deleteComment(`${conversation.id}`, `${comment.id}`);
    return this.options.repository.getConversationForProjectId(conversation.projectId);
  }
}

interface CommentComponentOptions {
  repository: CommentRepository
}
