import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './utils/reportWebVitals';
import { getLoginActions, initConfiguration } from './actions';
const App = React.lazy(() => import('./App'));

const { isLoggedIn, login } = getLoginActions();

// @ts-ignore
window.react = React;

isLoggedIn().then(async (status) => {
  if (status) {

    await initConfiguration();

    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  } else {
    if (window.location.href.indexOf('code') === -1) {
      const Login = () => {
        return (
          <div className="login" style={{ backgroundImage: 'url("/illustration")' }}>
            <div className="login__outer">
              <div className="login__inner">
                <img src="/bannerlogo" alt="" />
                <div>You need to login to use the Delivery Portal</div>
                <button className="btn__dark--large" onClick={login}>Login</button>
              </div>
            </div>
          </div>
        )
      }
      ReactDOM.render(
        <React.StrictMode>
          <Login />
        </React.StrictMode>,
        document.getElementById('root')
      );
    }
  }
})
