import { Project, ProjectState, ProjectType, generateEmbedCode, checkIfStateChangeIsValid, Widget } from "@delivery-portal/utils";
import ProjectRepository from "./ProjectRepository";
import { v4 as uuidv4 } from 'uuid';
import WidgetComponent from "../widget/WidgetComponent";
import CommentComponent from "../comments/CommentComponent";

export const ProjectComponentError = {
  CREATE_PROJECT_ERROR: 'create-project-error',
  SAVE_PROJECT_ERROR: 'save-project-error',
  MISSING_PROJECT_DATA: 'missing-project-data',
  MISSING_MASTER_LANGUAGE: 'missing-master-language',
  MISSING_TRANSLATION_LANGUAGE: 'missing-translation-language',
}
export enum ContentEditState {
  NONE = 0,
  TRANSLATE = 1,
  TEXT = 2,
  ALL = 3,
}


export default class ProjectComponent {
  private options: ProjectComponentOptions;

  constructor(options: ProjectComponentOptions) {
    this.options = options;
  }

  async getAllProjects(): Promise<Project[]> {
    return this.options.repository.listProjects();
  }

  async createNewProject(data: CreateProjectTO): Promise<string> {
    const id = uuidv4();
    if (data.name && data.widgetId && data.widgetTheme && data.widgetVersion && data.language) {
      const project: Project = {
        id,
        masterRefId: data.masterRefId || id,
        name: data.name,
        systemName: this.generateSystemName(data.name),
        creationDate: new Date(),
        widgetId: data.widgetId,
        widgetVersion: data.widgetVersion,
        widgetTheme: data.widgetTheme,
        content: data.content,
        language: data.language,
        type: data.type || ProjectType.NON_COMPLIANCE,
        state: data.state === ProjectState.MASTER_COPY ? data.state : ProjectState.DEV,
        lastModified: new Date(),
        masterLanguage: data.masterLanguage || data.language,
        users: [],
      }
      const savedProjectId = await this.options.repository.createProject(project);

      if (savedProjectId !== id) throw new Error(ProjectComponentError.CREATE_PROJECT_ERROR);

      return savedProjectId;
    }
    throw new Error(ProjectComponentError.MISSING_PROJECT_DATA);
  }

  async saveProject(project: Project): Promise<string> {
    const savedProjectId = await this.options.repository.updateProject(project);
    if (savedProjectId !== project.id) throw new Error(ProjectComponentError.SAVE_PROJECT_ERROR);
    return savedProjectId;
  }

  async getExportURLForProjectId(projectId: string): Promise<string> {
    return this.options.repository.exportProject(projectId);
  }

  async getPreviewURLForProjectId(projectId: string): Promise<string> {
    return this.options.repository.previewProject(projectId);
  }

  async generateEmbedCode(project: Project, widget: Widget, containerSelector: string): Promise<string> {
    if (project && widget) {
      const embedCode = generateEmbedCode({
        project,
        manifest: widget.manifest,
        containerSelector,
        apiBasePath: project.state !== ProjectState.RELEASE ? this.options.embedCodeApiBasePath : undefined
      });
      return `${embedCode.cssLink}
${embedCode.jsScript}
      `;
    }

    return '';
  }

  async sendProjectForTranslation(project: Project, deadline: Date): Promise<string> {
    if (!project.masterLanguage) throw new Error(ProjectComponentError.MISSING_MASTER_LANGUAGE);
    if (project.masterLanguage === project.language) throw new Error(ProjectComponentError.MISSING_TRANSLATION_LANGUAGE);
    project.state = ProjectState.TRANSLATION_PENDING;
    const translationId = await this.options.repository.translateProject(project.id, deadline);
    await this.saveProject(project);
    return translationId;
  }

  async deleteProject(project: Project): Promise<boolean> {
    return this.options.repository.deleteProject(project.id);
  }

  getValidStates(project: Project): ProjectState[] {
    const states: ProjectState[] = [];
    Object.values(ProjectState).forEach((projectState) => {
      if (checkIfStateChangeIsValid(project, projectState)) {
        states.push(projectState);
      }
    });
    return states;
  }

  isEditable(project: Project): boolean {
    switch (project.state) {
      case ProjectState.MASTER_APPROVAL:
      case ProjectState.MASTER_COPY:
      case ProjectState.TRANSLATION_APPROVAL:
      case ProjectState.RELEASE:
      case ProjectState.ARCHIVE:
        return false;
      default:
        return true;
    }
  }

  isMetaDataEditable(project: Project): boolean {
    switch (project.state) {
      case ProjectState.DEV:
      case ProjectState.MASTER_COPY:
        return true;
      default:
        return false;
    }
  }

  getContendEditState(project: Project): ContentEditState {
    switch (project.state) {
      case ProjectState.DEV:
      case ProjectState.DEV_REVIEW:
      case ProjectState.TRANSLATION_DEV:
        return ContentEditState.ALL;
      case ProjectState.MASTER_REVIEW:
        return ContentEditState.TEXT;
      case ProjectState.TRANSLATION_PENDING:
      case ProjectState.TRANSLATION_REVIEW:
        return ContentEditState.TRANSLATE
    }
    return ContentEditState.NONE;
  }

  private generateSystemName(name: string): string {
    return name.replaceAll(' ', '-').toLowerCase().replace(/[^\x20-\x7F]/g, '');
  }
}

interface ProjectComponentOptions {
  embedCodeApiBasePath: string
  repository: ProjectRepository
  widgetComponent: WidgetComponent
  commentComponent: CommentComponent
}

export interface CreateProjectTO extends Partial<Project> { }

