export interface Comment {
  workItemId: number
  id: number
  text: string
  createdDate: string
  modifiedDate: string
}

export class Conversation {
  id: number = -1;
  projectId: string = '';
  comments: Comment[] = [];
}
