import MediaComponent from '../domains/media/MediaComponent';

export default class MediaController {

  private component: MediaComponent;

  constructor(component: MediaComponent) {
    this.component = component;

    this.withMedia = this.withMedia.bind(this);
  }

  withMedia(Component: any) {
    return (props: any) => {
      const submitUpload = async (data: MediaControllerUploadTO):Promise<string> => {
        // upload file
        const response = await this.component.createMedia({
          fileName: data.filename,
          fileData: data.fileData,
          altTitle: data.altTitle
        });

        return response.filename;
      }

      const controllerProps: MediaProps = {
        media: {
          submitUpload,
          getMediaPath: (mediaName, isPreview) => {
            return this.component.getMediaURL(mediaName, isPreview);
          }
        }
      };
      return <Component {...props} {...controllerProps} />;
    };
  };
}

export interface MediaProps {
  media: {
    submitUpload: (data: MediaControllerUploadTO) => Promise<string>
    getMediaPath: (mediaName: string, isPreview: boolean) => Promise<string>
  }
}

export interface MediaControllerUploadTO {
  filename: string;
  altTitle: string;
  fileData: File;
}

