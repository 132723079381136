import { useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router";

export default class UIController {
  private options: UIControllerOptions;

  constructor(options: UIControllerOptions) {
    this.options = options;

    this.withModal = this.withModal.bind(this);
  }

  withModal(Component: any) {
    return (props: any) => {
      const [title, setTitle] = useState("");
      const [errorMessage, setErrorMessage] = useState("");
      const [width, setWidth] = useState<string>();
      const [message, setMessage] = useState("");

      const history = useHistory();
      const onCloseClick = () => {
        history.goBack();

        if (this.options.onModalHide) this.options.onModalHide();
      };

      if (this.options.onModalShow) this.options.onModalShow();
      const actionProps: ModalProps = {
        modal: {
          setTitle: (title) => {
            setTitle(title);
          },
          setMessage: (message, isError, isSticky) => {
            if (isError) {
              setMessage("");
              setErrorMessage(message);
            } else {
              setErrorMessage("");
              setMessage(message);
            }

            if (!isSticky) {
              setTimeout(() => {
                setMessage('');
                setErrorMessage('');
              }, 3000);
            }
          },
          clearMessage: () => {
            setMessage('');
            setErrorMessage("");
          },
          setWidth: (pxOrPercent) => {
            setWidth(pxOrPercent);
          },
        },
      };

      const resolveWidthStyle = () => {
        if (width) {
          const widthStyle = { width: "" };
          if (width.endsWith("px")) {
            widthStyle.width = width;
          } else if (width.endsWith("%")) {
            widthStyle.width = `${document.body.clientWidth *
              (parseInt(width.substr(0, width.length - 1)) / 100)
              }px`;
          }

          return widthStyle;
        }
        return {};
      };

      if (this.options.modalContainer) {
        return createPortal(
          <>
            <div className="wrapper" style={{ ...resolveWidthStyle() }}>
              <div className="header">
                <div>{title}</div>
                <span className="modal-close-button" onClick={onCloseClick} />
              </div>
              {message && <div className="message">{message}</div>}
              {errorMessage && (
                <div className="message error">{errorMessage}</div>
              )}
              <Component key="with-modal" {...props} {...actionProps} />
            </div>
          </>,
          this.options.modalContainer
        );
      }

      return <Component key="with-modal" {...props} />;
    };
  }
}

interface UIControllerOptions {
  modalContainer?: Element;
  onModalShow?: () => void;
  onModalHide?: () => void;
}

export interface ModalProps {
  modal: {
    setTitle: (title: string) => void;
    setMessage: (message: string, isError?: boolean, isSticky?: boolean) => void;
    clearMessage: () => void;
    setWidth: (pxOrPercent: string) => void;
  };
}
